import React from 'react';
import './HomePage.css';
import picture8 from './Picture_8.jpeg';
import picture9 from './Picture_9.jpeg';

function HomePage() {
  return (
    <div className="home">
      <div className="about">
        <h2>About Me</h2>
        <p>My name is Mahtab Shirdelan. I am 45 years old and a mother of two wonderful children, a 7-year-old daughter and a 19-year-old son. I hold a bachelor's degree in Chemistry and I am fluent in English and Farsi. I have a deep love for children and I am known for my kindness.</p>
      </div>
      <div className="house">
        <h2>About the House</h2>
        <p>The home daycare is located in the basement of our house, which has its own separate entrance. The space is about 700 sqft and is filled with natural light. Parents are welcome to request a home visit at any time.</p>
      </div>
      <div className="pictures">
        <img src={picture8} alt="Picture 8" />
        <img src={picture9} alt="Picture 9" />
      </div>
    </div>
  );
}

export default HomePage;