import React from 'react';
import './ContactPage.css';

function ContactPage() {
  return (
    <div className="contact-page">
      <h2>Contact Information</h2>
      <p>Email: info@mahtabcare.com</p>
      <p>Phone: 6475274552</p>
      <p>Location: Bradford West Gwillimbury</p>
      <p>Parents are welcomed to schedule a home visit any time they want and can also contact me anytime they want to talk.</p>
    </div>
  );
}

export default ContactPage;