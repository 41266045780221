import React from 'react';
import './ServicePage.css';

function ServicePage() {
  return (
    <div className="service-page">
      <h2>Our Services</h2>
      <div className="service">
        <h3>Full Day Daycare</h3>
        <p>$50 per day</p>
      </div>
      <div className="service">
        <h3>Before and After</h3>
        <p>$25 per day</p>
      </div>
      <div className="service">
        <h3>Half-Day</h3>
        <p>$30 per day</p>
      </div>
      <div className="notice">
        <p>We are actively following the process to become a licensed home daycare, but are unlicensed as of right now.</p>
      </div>
    </div>
  );
}

export default ServicePage;