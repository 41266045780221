import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <p>Email: info@mahtabcare.com</p>
      <p>Phone: 6475274552</p>
      <p>© MahtabCare. All rights reserved.</p>
    </div>
  );
}

export default Footer;