import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import ServicePage from './components/ServicePage';
import ContactPage from './components/ContactPage';

function App() {
  return (
    <div>
      <Header />
      <HomePage />
      <ServicePage />
      <ContactPage />
    </div>
  );
}

export default App;